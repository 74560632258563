import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosAdapter } from "../../auth/axiosAdapter";
import { zumosApiConstants } from "../../constants/ZumosConstants";

export const postOrder = createAsyncThunk('order/postOrder', (data) => {
  console.log("orderSlice::postOrder:: " + JSON.stringify(data))
  return axiosAdapter().post(zumosApiConstants.ORDER_RESOURCE, data)
    .then((response) => response.data)
})

export const fetchOrders = createAsyncThunk('order/fetchOrders', (data) => {
  return axiosAdapter().get(zumosApiConstants.ORDER_RESOURCE, { params: data })
    .then((response) => response.data)
})

export const fetchBaseOrders = createAsyncThunk('order/fetchBaseOrders', (data) => {
  return axiosAdapter().get(zumosApiConstants.BASE_ORDER_RESOURCE, { params: data })
      .then((response) => response.data)
})

export const deleteOrder = createAsyncThunk('order/deleteOrder', (data) => {
  console.log("order::deleteOrder:: " + JSON.stringify(data))
  return axiosAdapter().delete(zumosApiConstants.ORDER_RESOURCE + "/" + data._id)
    .then((response) => response.data)
})