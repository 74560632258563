// @ts-ignore
import { createAsyncThunk } from '@reduxjs/toolkit'
import { zumosApiConstants } from '../../constants/ZumosConstants'
import { axiosAdapter } from "../../auth/axiosAdapter";

const createSlice = require('@reduxjs/toolkit').createSlice

const initialState = {
    sales: undefined,
    filters: {
      startDateFilter: undefined,
      endDateFilter: undefined,
    },
    cart: {
        product: {
            variant: null,
            price: 0,
            quantity: 1,
            saleDate: null
        },
    },
}

export const postSale = createAsyncThunk('sale/postSale', (saleData) => {
    console.log("saleSlice::postSale:: " + JSON.stringify(saleData))
    return axiosAdapter().post(zumosApiConstants.SALE_RESOURCE, saleData)
        .then((response) => response.data)
})

export const fetchSales = createAsyncThunk('sale/fetchSales', (data) => {
    return axiosAdapter().get(zumosApiConstants.SALE_RESOURCE, { params: data })
        .then((response) => response.data)
})

export const deleteSale = createAsyncThunk('sale/deleteSale', (saleData) => {
    console.log("sale::deleteSale:: " + JSON.stringify(saleData))
    return axiosAdapter().delete(zumosApiConstants.SALE_RESOURCE + "/" + saleData._id)
        .then((response) => response.data)
})

const salesSlice = createSlice({
    name: 'sale',
    initialState,
    reducers: {
        addCartProduct: (state, action) => {
            let product = { ...action.payload }
            delete product._id;
            product.category = product.category._id
            state.cart.product = product
        },
        addCartVariant: (state, action) => {
            state.cart.product.variant = action.payload
        },
        addCartPrice: (state, action) => {
            state.cart.product.price = action.payload
        },
        addCartQuantity: (state, action) => {
            state.cart.product.quantity = action.payload
            console.log("addCartQuantity::: " + action.payload)
        },
        addCartSaleDate: (state, action) => {
            state.cart.product.saleDate = new Date(action.payload)
        }
    },
    extraReducers: (builder) => {
        builder.addCase(postSale.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(postSale.fulfilled, (state, action) => {
            state.loading = false;
            state.sales = [action.payload, ...state.sales];
            state.sales.error = ''
        })
        builder.addCase(postSale.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message
        })

        builder.addCase(fetchSales.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchSales.fulfilled, (state, action) => {
            state.loading = false;
            state.sales = action.payload.sales;
            state.filters.startDateFilter = action.payload.startDate;
            state.filters.endDateFilter = action.payload.endDate;
            state.error = ''
        })
        builder.addCase(fetchSales.rejected, (state, action) => {
            state.loading = false;
            state.sales = []
            state.error = action.error.message
        })

        builder.addCase(deleteSale.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(deleteSale.fulfilled, (state, action) => {
            state.loading = false;
            state.sales = state.sales.filter((product) => product._id !== action.payload._id);
            state.sales.error = ''
        })
        builder.addCase(deleteSale.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message
        })
    }
})

export const { addCartProduct, addCartVariant, addCartPrice, addCartQuantity, addCartSaleDate } = salesSlice.actions
export default salesSlice.reducer