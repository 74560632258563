import {LinearProgress, Typography} from "@mui/material";

const LoadingResource = () => {
    return (
        <div>
            <Typography variant="body2" color="textSecondary" marginY={1} marginLeft={2}>
                Cargando...
            </Typography>
            <LinearProgress color={"secondary"}/>
        </div>

    )
}

export default LoadingResource;