import { configureStore } from '@reduxjs/toolkit'
import categorySlice from './category/categorySlice'
import closedaySlice from './closeday/closedaySlice'
import productSlice from './product/productSlice'
import outflowSlice from "./outflow/outflowSlice";
import purchaseSlice from './purchase/purchaseSlice'
import saleSlice from './sale/saleSlice'
import userSlice from "./user/userSlice";
import orderSlice from './order/orderSlice';
import clientSlice from './client/clientSlice';

const store = configureStore({
    reducer: {
        category: categorySlice,
        product: productSlice,
        sale: saleSlice,
        purchase: purchaseSlice,
        closeday: closedaySlice,
        user: userSlice,
        outflow: outflowSlice,
        order: orderSlice,
        client: clientSlice,
    }
})

export default store