import { zumosApiConstants } from '../../constants/ZumosConstants'
import { axiosAdapter } from "../../auth/axiosAdapter";

const createSlice = require('@reduxjs/toolkit').createSlice
const createAsyncThunk = require('@reduxjs/toolkit').createAsyncThunk

const initialState = {
    loading: false,
    products: [],
    error: ''
}

export const fetchProducts = createAsyncThunk('product/fetchProducts', (categoryId) => {
    return axiosAdapter().get(zumosApiConstants.PRODUCT_RESOURCE)
        .then((response) => response.data)
})

const productSlice = createSlice({
    name: 'product',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchProducts.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchProducts.fulfilled, (state, action) => {
            state.loading = false;
            state.products = action.payload
            state.error = ''
        })
        builder.addCase(fetchProducts.rejected, (state, action) => {
            state.loading = false;
            state.products = []
            state.error = action.error.message
        })
    }
})

export default productSlice.reducer