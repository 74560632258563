import { createAsyncThunk } from '@reduxjs/toolkit'
import { zumosApiConstants } from '../../constants/ZumosConstants'
import { axiosAdapter } from "../../auth/axiosAdapter";

const createSlice = require('@reduxjs/toolkit').createSlice

const initialState = {
    purchases: [],
    postPurchase: {
        loading: false,
        newPurchase: {},
        error: ''
    },
}

export const postPurchase = createAsyncThunk('purchase/postPurchase', (purchaseData) => {
    console.log("purchaseSlice::postPurchase:: " + JSON.stringify(purchaseData))
    return axiosAdapter().post(zumosApiConstants.PURCHASE_RESOURCE, purchaseData)
        .then((response) => response.data)
})

export const fetchPurchases = createAsyncThunk('purchase/fetchPurchases', (data) => {
    return axiosAdapter().get(zumosApiConstants.PURCHASE_RESOURCE, { params: data })
        .then((response) => response.data)
})

export const deletePurchase = createAsyncThunk('purchase/deletePurchase', (purchaseData) => {
    console.log("purchaseSlice::deletePurchase:: " + JSON.stringify(purchaseData))
    return axiosAdapter().delete(zumosApiConstants.PURCHASE_RESOURCE + "/" + purchaseData._id)
        .then((response) => response.data)
})

const purchasesSlice = createSlice({
    name: 'purchase',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(postPurchase.pending, (state) => {
            state.postPurchase.loading = true;
        })
        builder.addCase(postPurchase.fulfilled, (state, action) => {
            state.postPurchase.loading = false;
            state.postPurchase.newPurchase = action.payload
            state.postPurchase.error = ''
        })
        builder.addCase(postPurchase.rejected, (state, action) => {
            state.postPurchase.loading = false;
            state.postPurchase.newPurchase = []
            state.postPurchase.error = action.error.message
        })

        builder.addCase(fetchPurchases.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchPurchases.fulfilled, (state, action) => {
            state.loading = false;
            state.purchases = action.payload
            state.error = ''
        })
        builder.addCase(fetchPurchases.rejected, (state, action) => {
            state.loading = false;
            state.purchases = []
            state.error = action.error.message
        })

        builder.addCase(deletePurchase.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(deletePurchase.fulfilled, (state, action) => {
            state.loading = false;
            state.purchases = state.purchases.filter((product) => product._id !== action.payload._id);
            state.error = ''
        })
        builder.addCase(deletePurchase.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message
        })
    }
})

export default purchasesSlice.reducer