const API_URL = process.env.REACT_APP_BASE_API_URL ?? "http://localhost:3000"

export const zumosApiConstants = {
    CATEGORY_RESOURCE:  API_URL + "/api/category",
    PRODUCT_RESOURCE:  API_URL + "/api/product",
    SALE_RESOURCE:  API_URL + "/api/sale",
    PURCHASE_RESOURCE:  API_URL + "/api/purchase",
    CLOSEDAY_RESOURCE:  API_URL + "/api/closeday",
    REPORT_CUADRE:  API_URL + "/api/report/cuadre",
    OUTFLOW_RESOURCE:  API_URL + "/api/outflow",
    USER_RESOURCE: API_URL + "/api/user",
    ORDER_RESOURCE:  API_URL + "/api/order",
    BASE_ORDER_RESOURCE:  API_URL + "/api/order/base",
    CLIENT_RESOURCE:  API_URL + "/api/client",
    AUTH_RESOURCE: API_URL + "/login",
}

export const productConstants = {
    UNKNOWN_PRODUCT_ID: '66fb66666a6fdfdd6ea6a66a'
}

export const DEFAULT_CLIENT_ID = '65fee25d13ba3c1c82a7c607';

export const ZUMOS_ROLES = {
    ADMIN: 'admin',
    USER: 'user',
    MESERO: 'mesero',
}