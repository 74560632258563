import { createAsyncThunk } from '@reduxjs/toolkit'
import { zumosApiConstants } from '../../constants/ZumosConstants'
import { axiosAdapter } from "../../auth/axiosAdapter";

const createSlice = require('@reduxjs/toolkit').createSlice

const initialState = {
    clients: [],
    loading: false,
}

export const postClient = createAsyncThunk('client/postClient', (data) => {
    return axiosAdapter().post(zumosApiConstants.CLIENT_RESOURCE, data)
        .then((response) => response.data)
})

export const fetchClients = createAsyncThunk('client/fetchClients', (data) => {
    return axiosAdapter().get(zumosApiConstants.CLIENT_RESOURCE, { params: data })
      .then((response) => response.data)
})

export const deleteClient = createAsyncThunk('client/deleteClient', (data) => {
    return axiosAdapter().delete(zumosApiConstants.CLIENT_RESOURCE + "/" + data._id)
      .then((response) => response.data)
})

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(postClient.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(postClient.fulfilled, (state, action) => {
            state.loading = false;
            state.error = '';

            const theClient = state.clients.find((c) => c._id === action.payload._id);
            if (theClient) {
                state.clients = state.clients.map((c) => (c._id === action.payload._id ? action.payload : c));
            } else {
                state.clients = [...state.clients, action.payload];
            }

        })
        builder.addCase(postClient.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message
        })

        builder.addCase(fetchClients.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchClients.fulfilled, (state, action) => {
            state.loading = false;
            state.clients = action.payload
            state.error = ''
        })
        builder.addCase(fetchClients.rejected, (state, action) => {
            state.loading = false;
            state.clients = []
            state.error = action.error.message
        })

        builder.addCase(deleteClient.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(deleteClient.fulfilled, (state, action) => {
            state.loading = false;
        })
        builder.addCase(deleteClient.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message
        })
    }
})

export default clientSlice.reducer