import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux'
import store from './redux/store';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import LoadingResource from "./components/comun/LoadingResource";
const App = React.lazy(() => import('./App'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Suspense fallback={<LoadingResource />}>
                    <App />
                </Suspense>
            </LocalizationProvider>
        </Provider>
    </React.StrictMode>
);
