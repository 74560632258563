import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosAdapter } from "../../../auth/axiosAdapter";
import { zumosApiConstants } from "../../../constants/ZumosConstants";

export const patchOrder = createAsyncThunk('order/patchOrder', (data) => {
  const orderId = data.orderId;
  delete data.orderId;
  console.log("orderSlice::patchOrder:: " + JSON.stringify(data))
  return axiosAdapter().patch(`${zumosApiConstants.ORDER_RESOURCE}/${orderId}`, data)
    .then((response) => response.data)
})


export const patchOrderFullfilled = (state, action) => {
  state.orders = state.orders.map((order) => {
    if (order._id === action.payload._id) {
      return action.payload;
    }
    return order;
  });
  state.loading = false;
  state.error = ''
};
export const patchOrderPending = (state) => {
  state.loading = true;
};
export const patchOrderRejected = (state, action) => {
  state.loading = false;
  state.error = action.error.message
}