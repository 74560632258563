import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosAdapter } from "../../../auth/axiosAdapter";
import { zumosApiConstants } from "../../../constants/ZumosConstants";

export const deleteOrderItemExtra = createAsyncThunk('order/deleteOrderItemExtra', (data) => {
  const orderId = data.orderId;
  const orderItemId = data.orderItemId;
  const extraId = data.extraId;
  console.log("orderSlice::deleteOrderItemExtra:: " + JSON.stringify(data))
  return axiosAdapter().delete(`${zumosApiConstants.ORDER_RESOURCE}/${orderId}/item/${orderItemId}/${extraId}`, data)
    .then((response) => response.data)
})


export const deleteOrderItemExtraFullfilled = (state, action) => {
  state.orders = state.orders.map((order) => {
    if (order._id === action.payload._id) {
      return action.payload;
    }
    return order;
  });
  state.loading = false;
  state.error = ''
};
export const deleteOrderItemExtraPending = (state) => {
  state.loading = true;
};
export const deleteOrderItemExtraRejected = (state, action) => {
  state.loading = false;
  state.error = action.error.message
}