import { createAsyncThunk } from '@reduxjs/toolkit'
import { zumosApiConstants } from '../../constants/ZumosConstants'
import { axiosAdapter } from "../../auth/axiosAdapter";

const createSlice = require('@reduxjs/toolkit').createSlice

const initialState = {
    postOutflow: {
        loading: false,
        newOutflow: {},
        error: ''
    },
    outflows: [],
    loading: false,
}

export const postOutflow = createAsyncThunk('outflow/postOutflow', (outflowData) => {
    console.log("outflowSlice::postOutflow:: " + JSON.stringify(outflowData))
    return axiosAdapter().post(zumosApiConstants.OUTFLOW_RESOURCE, outflowData)
        .then((response) => response.data)
})

export const fetchOutlflows = createAsyncThunk('outflow/fetchOutflows', (data) => {
    return axiosAdapter().get(zumosApiConstants.OUTFLOW_RESOURCE, { params: data })
      .then((response) => response.data)
})

export const deleteOutlflow = createAsyncThunk('outflow/deleteOutflow', (data) => {
    return axiosAdapter().delete(zumosApiConstants.OUTFLOW_RESOURCE + "/" + data._id)
      .then((response) => response.data)
})

const outflowSlice = createSlice({
    name: 'outflow',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(postOutflow.pending, (state) => {
            state.postOutflow.loading = true;
        })
        builder.addCase(postOutflow.fulfilled, (state, action) => {
            state.postOutflow.loading = false;
            state.postOutflow.newOutflow = action.payload
            state.postOutflow.error = ''
        })
        builder.addCase(postOutflow.rejected, (state, action) => {
            state.postOutflow.loading = false;
            state.postOutflow.newOutflow = []
            state.postOutflow.error = action.error.message
        })
        builder.addCase(fetchOutlflows.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchOutlflows.fulfilled, (state, action) => {
            state.loading = false;
            state.outflows = action.payload
            state.error = ''
        })
        builder.addCase(fetchOutlflows.rejected, (state, action) => {
            state.loading = false;
            state.outflows = []
            state.error = action.error.message
        })

        builder.addCase(deleteOutlflow.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(deleteOutlflow.fulfilled, (state, action) => {
            state.loading = false;
        })
        builder.addCase(deleteOutlflow.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message
        })
    }
})

export default outflowSlice.reducer