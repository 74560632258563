import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosAdapter } from "../../../auth/axiosAdapter";
import { zumosApiConstants } from "../../../constants/ZumosConstants";

export const postOrderItem = createAsyncThunk('order/postOrderItem', (data) => {
  const orderId = data.orderId;
  delete data.orderId;

  console.log("orderSlice::postOrderItem:: " + JSON.stringify(data))
  return axiosAdapter().post(`${zumosApiConstants.ORDER_RESOURCE}/${orderId}/item`, data)
    .then((response) => response.data)
})
export const postOrderItemFullfilled = (state, action) => {
  console.log('the vreturne dvalue is', action.payload);
  state.orders = state.orders.map((order) => {
    if (order._id === action.payload._id) {
      return action.payload;
    }
    return order;
  });
  state.loading = false;
  state.error = ''
};
export const postOrderItemPending = (state) => {
  state.loading = true;
};
export const postOrderItemRejected = (state, action) => {
  state.loading = false;
  state.error = action.error.message
}