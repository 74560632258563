import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosAdapter } from "../../auth/axiosAdapter";
import { zumosApiConstants } from "../../constants/ZumosConstants";

const createSlice = require('@reduxjs/toolkit').createSlice

const initialState = {
  isAdmin: false,
  user: null,
  error: '',
  loading: false,
}

export const fetchUser = createAsyncThunk('user/fetchUser', () => {
  return axiosAdapter().get(zumosApiConstants.USER_RESOURCE + '/me')
    .then((response) => response.data)
})

const userSlice = createSlice({
  name: 'user',
  initialState,
   reducers: {
    setIsAdmin(state, action) {
       state.isAdmin = action.payload;
     },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload
      state.isAdmin = action.payload.role === 'admin';
      state.error = ''
    })
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.loading = false;
      state.user = null
      state.error = action.error.message
    })
  }
})

export const { setIsAdmin } = userSlice.actions
export default userSlice.reducer