import { createAsyncThunk } from '@reduxjs/toolkit'
import { zumosApiConstants } from '../../constants/ZumosConstants'
import { axiosAdapter } from "../../auth/axiosAdapter";

const createSlice = require('@reduxjs/toolkit').createSlice

const initialState = {
    closedays: [],
    postCloseday: {
        loading: false,
        newCloseday: {},
        error: ''
    }
}

export const postCloseday = createAsyncThunk('closeday/postCloseday', (closedayData) => {
    console.log("closedaySlice::postCloseday:: " + JSON.stringify(closedayData))
    return axiosAdapter().post(zumosApiConstants.CLOSEDAY_RESOURCE, closedayData)
        .then((response) => response.data)
})

export const fetchClosedays = createAsyncThunk('closeday/fetchClosedays', (closedayData) => {
    return axiosAdapter().get(zumosApiConstants.CLOSEDAY_RESOURCE, { params: closedayData })
        .then((response) => response.data)
})

const closedaysSlice = createSlice({
    name: 'closeday',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(postCloseday.pending, (state) => {
            state.postCloseday.loading = true;
        })
        builder.addCase(postCloseday.fulfilled, (state, action) => {
            state.postCloseday.loading = false;
            state.postCloseday.newCloseday = action.payload
            state.postCloseday.error = ''
        })
        builder.addCase(postCloseday.rejected, (state, action) => {
            state.postCloseday.loading = false;
            state.postCloseday.newCloseday = []
            state.postCloseday.error = action.error.message
        })

        builder.addCase(fetchClosedays.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchClosedays.fulfilled, (state, action) => {
            state.loading = false;
            state.closedays = action.payload
            state.error = ''
        })
        builder.addCase(fetchClosedays.rejected, (state, action) => {
            state.loading = false;
            state.closedays = []
            state.error = action.error.message
        })
    }
})

export default closedaysSlice.reducer