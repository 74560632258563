import Cookies from "js-cookie";
import axios from "axios";

export const axiosAdapter = () => {
  const jwt = Cookies.get('jwt');
  const adapter = axios.create({
    headers: {
      Authorization: `${jwt}`,
      Referer: 'https://api.ipify.org',
    },
  });
  return adapter;
}