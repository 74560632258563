// @ts-ignore
import { patchOrder, patchOrderFullfilled, patchOrderPending, patchOrderRejected } from "./order/patchOrder";
import {deleteOrder, fetchBaseOrders, fetchOrders, postOrder} from './orderAsyncThunk';
import {
    deleteOrderItem,
    deleteOrderItemFullfilled,
    deleteOrderItemPending,
    deleteOrderItemRejected
} from "./orderItem/deleteOrderItem";
import {
    postOrderItem,
    postOrderItemFullfilled,
    postOrderItemPending,
    postOrderItemRejected
} from "./orderItem/postOrderItem";
import {
    patchOrderItem,
    patchOrderItemFullfilled,
    patchOrderItemPending, patchOrderItemRejected
} from "./orderItem/patchOrderItem";
import {
    deleteOrderItemExtra,
    deleteOrderItemExtraFullfilled,
    deleteOrderItemExtraPending, deleteOrderItemExtraRejected
} from "./orderItemExtra/deleteOrderItemExtra";

const createSlice = require('@reduxjs/toolkit').createSlice

const initialState = {
    orders: undefined,
    base: {
    }
}

const ordersSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(postOrder.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(postOrder.fulfilled, (state, action) => {
            state.loading = false;
            state.orders = [action.payload, ...state.orders];
            state.orders.error = ''
        })
        builder.addCase(postOrder.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message
        })

        builder.addCase(fetchOrders.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchOrders.fulfilled, (state, action) => {
            state.loading = false;
            state.orders = action.payload.orders;
            state.error = ''
        })
        builder.addCase(fetchOrders.rejected, (state, action) => {
            state.loading = false;
            state.orders = []
            state.error = action.error.message
        })

        builder.addCase(fetchBaseOrders.pending, (state) => {
            state.base.loading = true;
        })
        builder.addCase(fetchBaseOrders.fulfilled, (state, action) => {
            state.base.loading = false;
            state.base.orders = action.payload.orders;
            state.base.error = ''
        })
        builder.addCase(fetchBaseOrders.rejected, (state, action) => {
            state.base.loading = false;
            state.base.orders = []
            state.base.error = action.error.message
        })

        builder.addCase(deleteOrder.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(deleteOrder.fulfilled, (state, action) => {
            state.loading = false;
            state.orders = state.orders.filter((order) => order._id !== action.payload._id);
            state.orders.error = ''
        })
        builder.addCase(deleteOrder.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message
        })
        /* ORDER */
        builder.addCase(patchOrder.pending, patchOrderPending)
        builder.addCase(patchOrder.fulfilled, patchOrderFullfilled)
        builder.addCase(patchOrder.rejected, patchOrderRejected)

        /*ORDER ITEM */
        builder.addCase(postOrderItem.pending, postOrderItemPending)
        builder.addCase(postOrderItem.fulfilled, postOrderItemFullfilled)
        builder.addCase(postOrderItem.rejected, postOrderItemRejected)

        builder.addCase(deleteOrderItem.pending, deleteOrderItemPending)
        builder.addCase(deleteOrderItem.fulfilled, deleteOrderItemFullfilled)
        builder.addCase(deleteOrderItem.rejected, deleteOrderItemRejected)

        builder.addCase(patchOrderItem.pending, patchOrderItemPending)
        builder.addCase(patchOrderItem.fulfilled, patchOrderItemFullfilled)
        builder.addCase(patchOrderItem.rejected, patchOrderItemRejected)

        builder.addCase(deleteOrderItemExtra.pending, deleteOrderItemExtraPending)
        builder.addCase(deleteOrderItemExtra.fulfilled, deleteOrderItemExtraFullfilled)
        builder.addCase(deleteOrderItemExtra.rejected, deleteOrderItemExtraRejected)
    }
})

export default ordersSlice.reducer